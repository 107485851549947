import React from 'react';
import { Helmet } from 'react-helmet';
import './cxfirst.css'; // Import your CSS file from the src/components folder
import cxfirstLogo from './images/cxfirst-logo.svg';
import { Link } from 'react-scroll';
import { Link as ReactDOMLink, useLocation, } from 'react-router-dom';


const App = ({ metaDescription, pageTitle }) => {
  const isLocalhost = window.location.hostname === 'localhost';
  const actualLink = isLocalhost
    ? 'http://localhost/cxfirst_web/'
    : 'https://cxfirst.ai/';

  const location = useLocation()

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content={metaDescription} />
        <meta name="robots" content="follow, index" />

        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={actualLink} />
        <meta property="og:site" content="https://cxfirst.ai/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={cxfirstLogo} />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:url" content={actualLink} />
        <meta name="twitter:site" content="https://twitter.com/FivesDigital" />
        <meta name="twitter:image" content={cxfirstLogo} />

        <meta name="google-site-verification" content="WlN4qb4cqsuOmDZKtsV3Bf4ft4UQ3WlBal5wssQvnkc" />
        {/* <!-- captcha script --> */}
        <script src="https://www.google.com/recaptcha/api.js?render=6LdVpNIjAAAAAJ__5YNKe51OD1LY9dlZyv0Od85d"></script>
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PNSJNXK');`}
        </script>
        {/* End Google Tag Manager */}

        <link rel="canonical" href={`https://${window.location.hostname}${window.location.pathname}`} />
        <title>{pageTitle}</title>

        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        {/* <link rel="shortcut icon" href={`${actualLink}assets/images/favicon.png`} /> */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css" />

      </Helmet>

      <header id="siteheader">
        
    {/* <!-- Google Tag Manager (noscript) --> */}
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PNSJNXK"
    height="0" width="0" style={{display:"none",visibility:"hidden"}} title="Google Tag Manager"></iframe></noscript>
    {/* <!-- End Google Tag Manager (noscript) --> */}
        <nav className="navbar navbar-expand-md">
          <div className="container">
            <a className="navbar-brand" href="/" data-scroll-nav="0">
              <img src={cxfirstLogo} alt="CxFirst" />
            </a>
            <div id="menu" className="collapse navbar-collapse mx-auto">
              <ul className="menu_list navbar-nav">
                <li className="menuicon">
                  <ReactDOMLink to="/">
                    <img src={cxfirstLogo} alt="CxFirst" />
                  </ReactDOMLink>
                </li>




                <li>
                  {location.pathname !== "/" ?
                    <ReactDOMLink to='/' >CxFirst Platform</ReactDOMLink>

                    :
                    <Link to='tshaped' smooth={true} duration={500} offset={-50} data-scroll-nav="1">CxFirst Platform</Link>
                  }
                </li>
                <li>
                  {location.pathname !== "/" ?
                    <ReactDOMLink to='/' data-scroll-nav="2">Why CxFirst</ReactDOMLink>
                    :
                    <Link to='platform' smooth={true} duration={500} offset={-50} data-scroll-nav="2">Why CxFirst</Link>
                  }
                </li>
                <li>
                  <ReactDOMLink to="/blogs" >Blogs</ReactDOMLink>
                </li>
                <li>
                  <ReactDOMLink to="/Contact" >Contact</ReactDOMLink>
                </li>
              </ul>
            </div>
            <button id="nav-toggle" className="d-md-none">
              <svg className="ham hamRotate ham8" viewBox="0 0 100 100" width="50">
                <path className="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                <path className="line middle" d="m 30,50 h 40" />
                <path className="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
              </svg>
            </button>
          </div>
        </nav>
      </header>
    </>
  );
};

// Default props for SEO
App.defaultProps = {
  metaDescription: 'Cx First',
  pageTitle: 'Cx First',
};

export default App;
