import React, { useContext } from 'react'
import Blogbanner from "./component/blogbanner";
import { AppContext } from '../AppContext';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BlogReadingPage = () => {
  const { blogId } = useParams();

  const { blogs } = useContext(AppContext);

  const blog = blogs.find(b => b.slug === blogId);

  const date = blog?.date.split(" ");

  if (!blog) {
    return <div>Blog not found!</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{blog?.title}</title>
      </Helmet>
      <Blogbanner />
      <div id="content">
        <div className="container text-start" id="blogDetail">
          <div className="row">
            <div className="col blogCol">
              <div className="row">
                <div className="col-auto">
                  <div className="blogDate">
                    {date.map((data, index) => {
                      return index === 1 ? <span key={index}>{data}</span> : data;
                    })}
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <h1 className="blogTitle">{blog?.title}</h1>
                </div>
              </div>

              <div className="text-center">
                <img src={blog?.image} alt="The Role of Artificial Intelligence in Enhancing Customer Service Quality" className="rounded-2" />
              </div>

              {blog?.content}

            </div>

            <div className="col-md-4 col-lg-3 d-none d-md-flex">
              <div className="card" id="aside_blist">
                <div className="card-header">All Blogs</div>
                <div className="card-body">
                  <ul>
                    {blogs.map((data, index) => {
                      return (
                        <li key={index}>
                          <h6><a href={data?.url}>{data?.title}</a></h6>
                          <small>{data?.date}</small>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogReadingPage
