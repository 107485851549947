import React, { useState, useRef, useContext } from "react";
import { Helmet } from 'react-helmet';
import CxFirstSnaps from "../images/cxfirst-snaps.svg";

import problem1 from "../images/problem-1.svg";
import problem2 from "../images/problem-2.svg";
import problem3 from "../images/problem-3.svg";
import problem4 from "../images/problem-4.svg";
import problem5 from "../images/problem-5.svg";
import problem6 from "../images/problem-6.svg";

import cxCircles from "../images/solutions.svg";
import cxTabs from "../images/cx-tabs.svg";

import dmsIcon from "../images/dms-icon.svg";
import qmsIcon from "../images/qms-icon.svg";
import tmsIcon from "../images/tms-icon.svg";
import dmsMain from "../images/dms-main.png";
import qmsMain from "../images/qms-main.png";
import tmsMain from "../images/tms-main.png";
import r1 from "../images/r1.svg";
import treelogo from "../images/treelogo.svg";
import treeBtnPath from "../images/tree-btn-path.svg";
import r2Left from "../images/r2-left.svg";
import r2Mid from "../images/r2-mid.svg";
import r2Right from "../images/r2-right.svg";
import team1 from "../images/team1.svg";
import team2 from "../images/team2.svg";
import team3 from "../images/team3.svg";
import clock from "../images/clock.svg";
import brain from "../images/brain.svg";
import smile from "../images/smile.svg";
import alert2 from "../images/alert2.svg";
import overallTime from "../images/overall-time.svg";
import { AppContext } from "../AppContext";
import EmailInput from "../components/EmailInput";

const Home = () => {
  const { option } = useContext(AppContext);

  const slides = [
    {
      id: 0,
      title: "First Impression is the Last Impression",
      content: (
        <>
          <p>Studies shows that 66% of B2B and 52% of B2C customers stopped buying after a bad customer service interaction.</p>
          <p>The Major reason was that the customer had to explain their pain multiple times to multiple people. Most of the times the customer facing team is not equipped with the right information at the right time and spoils the overall customer experience with a LOW CSAT SCORE, eventually.</p>
        </>
      ),
      image: problem1,
    },
    {
      id: 1,
      title: "Are you training them well?",
      content: (
        <>
          <p>Or in another words, How would you know the efficacy of your training programs when you already have the training programs and trainers in place.</p>
          <p>A report shows that 24% of participants acknowledge that their organization doesn’t pay enough attention to their employee’s learning requirements. This not only makes call center workers feel insecure in their jobs and disengaged but also affects customers in a negative way.</p>
          <p>A bad trained workforce can inversely impact the core business metrices like Quality Scores, CAST, Attrition and Fatal Errors.</p>
        </>
      ),
      image: problem2,
    },
    {
      id: 2,
      title: "How is your hiring going?",
      content: (
        <>
          <p>The average contact center has a turnover rate between 30-45%.</p>
          <p>Means, you have to drive the recruitment process throughout the year with backfilling the attrited resources. Studies show that 40% of time is just wasted in doing repetitive work to manage the Agent Onboarding Pipeline.</p>
          <p>Starting from raising the MRF to Releasing the agents to the production floor, have numerous of stages. Unfortunately, all is being carried with the help of old school tools, makes the entire pipeline ambiguous and unmanageable.</p>
        </>
      ),
      image: problem3,
    },
    {
      id: 3,
      title: "The curse of Random Sampling",
      content: (
        <>
          <p>Most of the Quality Teams still audits the interactions on just random basis. For instance, out of a population of 1000 interactions they are selecting for say 2% random interactions.</p>
          <p>The problem with this selection procedure is, they might get the interactions in the sample which are really not seeking their attention. The cost of this miss is, the agent who are really needing attention would be left out and they will continue to make mistakes which will lead to Low CSAT and High Fatals.</p>
        </>
      ),
      image: problem4,
    },
    {
      id: 4,
      title: "Cramming the SOPs, an eternal nightmare",
      content: (
        <>
          <p>To get high productivity out of the 0 to 30 days tenured agents, trainers generally expects them to remember all the SOPs so that they could respond to the customer queries without refering to SOP documents.</p>
          <p>The problem with this approach is, it creates a lot of mental friction and stress to be ready with the desired answer when the customer puts up the query. Some times the agent just go blank or disconnects the call because they could not recall the answers or what to do.</p>
        </>
      ),
      image: problem5,
    },
    {
      id: 5,
      title: "Longer the training period higher the operating cost",
      content: (
        <>
          <p>According to a report, approx. 20 to 30% of the agents in the contact centers are kept in the intensive training programs for at least 0 to 60 days tenure. The reason of keeping the agents for such a long time is to make them well versed in dealing with the customers. Within this time period, they generally do not much contribute to the BAU and this is where the operating cost spikes up. </p>
          <p>Along with this, a 30% to 40% turnover rate has been observed throughout an year, it means a big chunk of freshers will always be there in the training pool and this is apparently a cost to the businesses that their hired resources are not ready to contribute for a period time. </p>
        </>
      ),
      image: problem6,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(2); // Start with the third slide as active
  const slidesRef = useRef([]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : slides.length - 1;
      setTimeout(() => {
        slidesRef.current[newIndex].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 5); // Adjust the delay (in ms) for slower scrolling
      return newIndex;
    });
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex < slides.length - 1 ? prevIndex + 1 : 0;
      setTimeout(() => {
        slidesRef.current[newIndex].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 5); // Adjust the delay (in ms) for slower scrolling
      return newIndex;
    });
  };

  return (
    <>
      <div
        id="hero"
        className="d-flex align-items-center"
        data-scroll-index="0"
      >
        <Helmet>
          <title>Home</title>
        </Helmet>
        <div className="container">
          <div className="row float-start mb-3 text-start">
            <div className="col-lg-6 ">
              <h1>
                <span>One App</span> enabling your employees deliver better CX
              </h1>
              <p className="h5">
                Manage Guided Workflows, Hiring Pipelines, Quality
                <br />
                and more in just one place
              </p>
              <p className="h6">Would you like to see how we improve the CX?</p>
              <EmailInput btn_txt={"Set Demo"} />
            </div>
            <div className="col-lg-6">
              <img src={CxFirstSnaps} alt="cxfirst" className="heroimg" />
            </div>
          </div>
        </div>
      </div>
      <div id="problems">
        <div className="container">
          <h2 className="text-center">
            Curious to know what problems we are solving?
          </h2>
          <p className="h5 text-center">Ever wonder, how your contact center operations be improved? Well, just by knowing the challenges. Perhaps you might be facing the same issues! Want to overcome? Let's dive in.</p>

          <div id="problemSlider">
            <div className="slick slick-initialized slick-slider slick-dotted slick-vertical">
              <div className="slick-list draggable" style={{ height: "350px" }}>
                <div className="slick-track" style={{ opacity: 1 }}>
                  {slides.map((slide, index) => (
                    <div
                      key={slide.id}
                      ref={(el) => (slidesRef.current[index] = el)}
                      className={`slick-slide ${index === currentIndex ? "slick-active" : ""
                        }`}
                      data-slick-index={index}
                      role="tabpanel"
                      id={`slick-slide0${index}`}
                      style={{
                        display: "block",
                        transition: "transform 0.5s ease",
                      }}
                    >
                      <div>
                        <div
                          className="problem"
                          style={{
                            width: "100%",
                            display: "inline-block",
                            height: "348px",
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-auto">
                              <img
                                src={slide.image}
                                alt={`Problem ${slide.id + 1}`}
                              />
                            </div>
                            <div className="col-lg">
                              <h3 className="h3">{slide.title}</h3>
                              {slide.content}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div id="pronvs">
              <button
                type="button"
                className="slide-m-prev slick-arrow"
                onClick={handlePrevClick}
              >
                <i className="fa fa-angle-up"></i>
              </button>
              <div className="slide-m-dots">
                <ul className="slick-dots ps-0" role="tablist">
                  {slides.map((slide, index) => (
                    <li
                      key={slide.id}
                      className={index === currentIndex ? "slick-active" : ""}
                    >
                      <button
                        type="button"
                        role="tab"
                        id={`slick-slide-control0${index}`}
                        aria-controls={`slick-slide0${index}`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <button
                type="button"
                className="slide-m-next slick-arrow"
                onClick={handleNextClick}
              >
                <i className="fa fa-angle-down"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="cta1">
        <div className="container">
          <div className="row text-start">
            <div className="col lg-7">
              <h3>
                Tools/Processes are in place and you still think the teams are
                underdelivering in your contact centre and so is the sinking CX?
              </h3>
            </div>
            <div className="col-lg-5">
              <h4>We can help you</h4>
              <EmailInput btn_txt={"Let's Discuss"} />
            </div>
          </div>
        </div>
      </div>

      <div id="tshaped" data-scroll-index="1">
        <div className="container">
          <div className="row mb-3 text-start">
            <div className="col-lg-6">
              <h2>
                CXFirst, <span>A T-shaped solution</span> Designed & Developed
                as per your industry!
              </h2>
              <p className="h5">
                Holistically targets to the core challenges in your contact
                center which are impediments for your growth and brings
                operational and cx excellence.
              </p>
            </div>
            <div className="col-lg-6 img-container">
              <img
                src={cxCircles}
                alt="CxFirst Solutions"
                className="cxCircles"
              />
              <div className="abs cnc">
                <h4>Contact Center Needs</h4>
                <p>
                  Integrated tools to empower the customer facing teams &
                  operations
                </p>
                <ul>
                  <li>Guided Workflows</li>
                  <li>Quality Management System</li>
                  <li>Training Management System</li>
                </ul>
              </div>
              <div className="abs csn">
                <h4>Customer Needs</h4>
                <p>
                  Provide accurate and quick resolution on the customer queries
                  and lift up the customer satisfaction
                </p>
              </div>
              <div className="abs bsn">
                <h4>Business Needs</h4>
                <p>Impacts positively on the Core Business Metrices</p>
                <ul>
                  <li>Low AHT</li>
                  <li>High Quality Score</li>
                  <li>High CSAT Score</li>
                  <li>High SOP Adherence</li>
                  <li>Shorter Training Time</li>
                </ul>
              </div>
            </div>
            <div className="col d-md-none">
              <div className="cxtab-wrap">
                <div className="cxCircles_tabs">
                  <img src={cxTabs} alt="CxFirst" className="cxtabs" />
                  <div className="ctb tb1 highlight">
                    <span>Contact Center Needs</span>
                  </div>
                  <div className="ctb tb2 highlight1">
                    <span>Customer Needs</span>
                  </div>
                  <div className="ctb tb3 highlight2">
                    <span>Business Needs</span>
                  </div>
                </div>
              </div>

              <div id="cxCirclessl">
                <div className="highlight_contact">
                  <h4 className="">Contact Center Needs</h4>
                  <p>
                    Integrated tools to empower the customer facing teams &
                    operations
                  </p>
                  <ul>
                    <li>Guided Workflows</li>
                    <li>Quality Management System</li>
                    <li>Training Management System</li>
                  </ul>
                </div>

                <div className="highlight_customer">
                  <h4>Customer Needs</h4>
                  <p>
                    Provide accurate and quick resolution on the customer
                    queries and lift up the customer satisfaction
                  </p>
                </div>

                <div className="highlight_business">
                  <h4>Business Needs</h4>
                  <p>Impacts positively on the Core Business Metrices</p>
                  <ul>
                    <li>Low AHT</li>
                    <li>High Quality Score</li>
                    <li>High CSAT Score</li>
                    <li>High SOP Adherence</li>
                    <li>Shorter Training Time</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="platform" data-scroll-index="2">
        <div className="container text-start">
          <h2>An Integrated Solution Package</h2>
          <p className="h5">
            Comprised of state of the art tools to empower your cross functional
            teams in delivering the best of them and amaze your customers like
            never before.
          </p>

          <ul className="nav nav-pills" id="three_option">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${option === "Decision Management System" ? "active" : ""} presentation1`}
                data-bs-toggle="tab"
                data-bs-target="#dms-tab"
                type="button"
                role="tab"
                aria-selected="true"
              >
                <div className="imgwrapper">
                  <img src={dmsIcon} alt="Decision Management System" />
                </div>
                <h5>
                  <span>Decision</span> Management System
                </h5>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${option === "Quality Management System" ? "active" : ""} presentation2`}
                data-bs-toggle="tab"
                data-bs-target="#qms-tab"
                type="button"
                role="tab"
              >
                <div className="imgwrapper">
                  <img src={qmsIcon} alt="Quality Management System" />
                </div>
                <h5>
                  <span>Quality</span> Management System
                </h5>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${option === "Training Management System" ? "active" : ""} presentation3`}
                data-bs-toggle="tab"
                data-bs-target="#tms-tab"
                type="button"
                role="tab"
              >
                <div className="imgwrapper">
                  <img src={tmsIcon} alt="Training Management System" />
                </div>
                <h5>
                  <span>Training</span> Management System
                </h5>
              </button>
            </li>
          </ul>

          <div className="tab-content">
            <div
              className={`tab-pane fade ${option === "Decision Management System" ? "active show" : ""}  presentation1_content`}
              id="dms-tab"
              role="tabpanel"
              tabIndex="0"
            >
              <div className="row text-start">
                <div className="col-md-5">
                  <h4>Why do you need?</h4>
                  <ul>
                    <li>To reduce the training time</li>
                    <li>To Improve the Quality Scores</li>
                    <li>To Improve the CSAT</li>
                    <li>To reduce the Average Handling Time</li>
                    <li>
                      To improve the efficacy of the query resolution guidelines
                    </li>
                    <li>
                      To empower your customer facing teams and make them self
                      dependent
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <img
                    src={dmsMain}
                    alt="Decision Management System"
                    className="platform_mainimg"
                  />
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${option === "Quality Management System" ? "active show" : ""}  presentation2_content`}
              id="qms-tab"
              role="tabpanel"
              tabIndex="0"
            >
              <div className="row">
                <div className="col-md-5">
                  <h4>Why do you need?</h4>
                  <ul>
                    <li>
                      To design effective training programs for your teams to
                      improve the AHT, CSAT and Quality Scores
                    </li>
                    <li>To standardize your Audit Procedures</li>
                    <li>To get rid of old school tools to manage data</li>
                    <li>To understand the customer pain points </li>
                    <li>
                      To focus on the interactions which seeks your attention
                    </li>
                    <li>
                      Effective feedback mechanism to know what went well, what
                      went bad and what could have been better
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <img
                    src={qmsMain}
                    alt="Quality Management System"
                    className="platform_mainimg"
                  />
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${option === "Training Management System" ? "active show" : ""}  presentation3_content`}
              id="tms-tab"
              role="tabpanel"
              tabIndex="0"
            >
              <div className="row">
                <div className="col-md-5">
                  <h4>Why do you need?</h4>
                  <ul>
                    <li>
                      To Predict the batch requirements and make your partners
                      aware ahead of time
                    </li>
                    <li>
                      To get meaningful insights from each of the phases, helps
                      you in making effective decisions
                    </li>
                    <li>To measure performance per team/individual basis</li>
                    <li>To monitor the batch health</li>
                  </ul>
                </div>
                <div className="col">
                  <img
                    src={tmsMain}
                    alt="Training Management System"
                    className="platform_mainimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="whycx" data-scroll-index="3">
        <div className="container text-start">
          <h2>
            CxFirst integrates with your CRM/Telephony <br />
            and helps different teams be <span>productive and focused</span>
          </h2>

          <div id="tree">
            <ul className="r1">
              <span>CRM</span>
              <img src={r1} alt="" className="mt-4" />
              <span>Telephony</span>
            </ul>
            <ul>
              <li>
                <div className="treelogo">
                  <img src={treelogo} alt=" " />
                </div>
                <img
                  src={treeBtnPath}
                  alt="CX"
                  className="d-md-none tlogoPath"
                />
                <div className="r2-arrow d-none d-md-block">
                  <img src={r2Left} alt="" className="rfirst" />
                  {/* <img src={r2Mid} alt="" className="r2mid" /> */}
                  <img src={r2Mid} alt="" />
                  <img src={r2Right} alt="" className="rlast" />
                </div>

                <ul className="r2">
                  <li>
                    <span>
                      <strong>Decision Management System</strong>
                      <em>Customer Facing Team</em>
                    </span>
                    <ul>
                      <li>
                        <span>
                          Customer Facing Team
                          <img src={team1} alt="cxfirst" />
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>
                      <strong>Quality Management System</strong>
                      <em>Operations Team</em>
                    </span>
                    <ul>
                      <li>
                        <span>
                          Operations Team
                          <img src={team2} alt="cxfirst" />
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>
                      <strong>Training Management System</strong>
                      <em>Business, Hiring, Operations Team</em>
                    </span>
                    <ul>
                      <li>
                        <span>
                          Business, Hiring, Operations Team
                          <img src={team3} alt="cxfirst" />
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="cxstats">
        <div className="container">
          <div className="row text-start">
            <div className="col-lg-5">
              <h2>
                Measure tremendous improvements on your{" "}
                <span>core business metrices</span>
              </h2>
              <p className="h5">
                How about overcoming the challenges? CxFirst hits hard to the
                existing problems in your contact center, and enables to measure
                improvements on your core business metrices
              </p>
            </div>
            <div className="col-lg-7 stats">
              <div className="row">
                <div className="col-md-4 col-lg-6">
                  <div className="img-container">
                    <img src={clock} alt="" />
                  </div>
                  <div>
                    <div className="h1">
                      30<span>%</span>
                    </div>
                    <p>Decrease in Average Handling Time</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-6">
                  <div className="img-container">
                    <img src={brain} alt="" />
                  </div>
                  <div>
                    <div className="h1">
                      0<span>%</span>
                    </div>
                    <p>Memorization of SOPs</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-6">
                  <div className="img-container">
                    <img src={smile} alt="" />
                  </div>
                  <div>
                    <div className="h1">
                      45<span>%</span>
                    </div>
                    <p>Increase in CSAT</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-6">
                  <div className="img-container">
                    <img src={alert2} alt="" />
                  </div>
                  <div>
                    <div className="h1">
                      94<span>%</span>
                    </div>
                    <p>Decrease in Fatal Error</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-6">
                  <div className="img-container">
                    <img src={overallTime} alt="" />
                  </div>
                  <div>
                    <div className="h1">
                      70<span>%</span>
                    </div>
                    <p>Decrease in Overall Training Time</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="testimonials">
        <div className="container">
          <h2 className="text-center">Our happy clients say about us</h2>

          <div className="slick slick-initialized slick-slider text-start">
            <div className="slick-list draggable">
              <div
                className="slick-track"
                style={{
                  opacity: 1,
                  width: "936px",
                  transform: "translate3d(0px, 0px, 0px)",
                }}
              >
                <div
                  className="slick-slide slick-current slick-active"
                  data-slick-index="0"
                  aria-hidden="false"
                  style={{ width: "292px" }}
                >
                  <div>
                    <div style={{ width: "100%", display: "inline-block" }}>
                      <div className="d-flex">
                        <img
                          src="https://placehold.co/43/ddd/000?font=lato&text=QC"
                          alt=""
                        />
                        <div>
                          <div className="cmtname">
                            Director - Quality & Compliance
                          </div>
                          <div className="cmtdesig">
                            Leading Retail, Media Client
                          </div>
                        </div>
                      </div>
                      <p>
                        "Cxfirst has performed amazingly. In a POC with a
                        renowned car rental marketplace, we have got a
                        significant positive impact of 5 data points in Quality
                        Scores and CSAT, along with 15% reduction in AHT."
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="slick-slide slick-active"
                  data-slick-index="1"
                  aria-hidden="false"
                  style={{ width: "292px" }}
                >
                  <div>
                    <div style={{ width: "100%", display: "inline-block" }}>
                      <div className="d-flex">
                        <img
                          src="https://placehold.co/43/ddd/000?font=lato&text=RD"
                          alt=""
                        />
                        <div>
                          <div className="cmtname">
                            Regional Director - Delivery
                          </div>
                          <div className="cmtdesig">Leading E-Comm Client</div>
                        </div>
                      </div>
                      <p>
                        "For one of our customers, we were experiencing a high
                        turnaround time in the overall training of our customer
                        support executives, and that was impacting the BAU.
                        After bringing the CxFirst, we have measured a down rate
                        of 78% on the overall training time. Cheers to the
                        CxFirst team."
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="slick-slide slick-active"
                  data-slick-index="2"
                  aria-hidden="false"
                  style={{ width: "292px" }}
                >
                  <div>
                    <div style={{ width: "100%", display: "inline-block" }}>
                      <div className="d-flex">
                        <img
                          src="https://placehold.co/43/ddd/000?font=lato&text=TL"
                          alt=""
                        />
                        <div>
                          <div className="cmtname">Team Lead</div>
                          <div className="cmtdesig">
                            Leading Food Tech Client
                          </div>
                        </div>
                      </div>
                      <p>
                        "The Widget DMS App is just awesome. It saves a lot of
                        time in browsing over the SOPs. Now we can access any
                        scenario just on a single click which leads to control
                        the AHT and also helps us to answer the customers
                        accurately."
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
