import React, { useState } from 'react';
import Alert from "../images/alert.svg";
import Tick from "../images/tick.svg";
import Alert2 from "../images/alert2.svg";
import Loader from "../images/loader.svg";
import ThumbUp from "../images/thumb-up.svg";

const EmailInput = ({ btn_txt }) => {

    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState({ show: false, type: '', message: '' });
    const [loading, setLoading] = useState(false);

    const hideAlert = () => {
        setTimeout(() => {
            setAlert({ ...alert, show: false });
        }, 3000);
    };

    const validateEmail = (email) => {
        // Simple regex for email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailSubmit = async () => {
        if (!validateEmail(email)) {
            setAlert({ show: true, type: 'error', message: 'Please enter a valid email address!' });
            hideAlert();
            return;
        }

        setLoading(true);
        setAlert({ show: false, type: '', message: '' });

        try {
            const response = await fetch("https://demo.devcxfirst.com/contactDetails", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ emailId: email }),
            });

            if (response.ok) {
                setAlert({ show: true, type: 'success', message: "We've got your email address, will get back to you shortly." });
            } else {
                setAlert({ show: true, type: 'error', message: 'An error occurred. Please try again later.' });
            }
        } catch (error) {
            console.error("Error:", error);
            setAlert({ show: true, type: 'error', message: 'An error occurred. Please try again later.' });
        } finally {
            setLoading(false);
            hideAlert();
        }
    };

    return (
        <div id="demo" className="homedemo">
            <input
                id="proposal_mail1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Your business email"
                className="form-control"
            />
            <button
                onClick={() => handleEmailSubmit(email)}
                className="btn btn-primary"
            >
                {loading ?
                    <div className="loader2 proposal_loader1_loader">
                        <img src={Loader} alt="Loading..." />
                    </div>
                    : <span>{btn_txt}</span>}
                {/* <span>{btn_txt}</span>
                <div id="btn_set_demo_loader"></div>
                <div className="loader proposal_loader1_loader">
                    <img src={Loader} alt="Loading..." />
                </div> */}
                <div className="error proposal_loader1_error">
                    <img src={Alert} alt="Error" />
                </div>
                <div className="success proposal_loader1_success">
                    <img src={Tick} alt="Success" />
                </div>
            </button>



            {alert.show && (
                <div className={`msg-${alert.type}`}>
                    {alert.type === 'success' && <div
                        className="proposal_mail1 msg-success"
                    >
                        <img src={ThumbUp} alt="Success" /> We've got your email
                        address, will get back to you shortly.
                    </div>}
                    {alert.type === 'error' && <div
                        className="proposal_mail1err msg-error"
                    >
                        <img src={Alert2} alt="Error" /> Please enter a valid email
                        address!
                    </div>}
                </div>
            )}

        </div>
    )
}

export default EmailInput