import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

function Blogbanner() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <div id="pageheader">
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <p className="pageTitle text-start">Blog</p>
          </div>
          {location.pathname !== "/blogs" && <div className="col-auto" onClick={handleBack}>
            <span className="btn btn-outline-light"><i className="fa fa-angle-left"></i> Back</span>
          </div>}
        </div>

      </div>
    </div>
  )
}

export default Blogbanner
