import React, { useContext } from 'react';
import cxfirstLogo from './images/cxfirst-logo.svg';
import mailIcon from './images/mail-icon.svg';
import { Link } from 'react-scroll';
import { AppContext } from '../AppContext'; // Import the context
import { Link as ReactDOMLink } from 'react-router-dom';
import EmailInput from './EmailInput';
// import callIcon from './assets/images/call-icon.svg'; // Uncomment if needed

const Footer = () => {
  const { setOption } = useContext(AppContext);
  return (
    <footer id="sitefooter">
      <div className="container text-start">
        <div id="fcta">
          <h2>
            <span>You just need ONE APP </span><br />
            to make your team's life easy! <br />
            Just ONE APP.
          </h2>
          <EmailInput btn_txt={"Let's Empower"} />
        </div>
      </div>

      <div id="mainfooter">
        <div className="container text-start">
          <div className="row">
            <div className="col-md">
              <img src={cxfirstLogo} alt="CxFirst" />
              <div className="footcxinfo">One App enabling your employees <br /> deliver better CX</div>
              <p className="mt-4 mb-2">
                <a href="mailto:support@cxfirst.ai">
                  <img src={mailIcon} alt="Email" />
                  support@cxfirst.ai
                </a>
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h5>CXFirst Platform</h5>
              <ul>
                <li className='li-cursor'><Link onClick={() => setOption("Decision Management System")} to='three_option' smooth={true} duration={500} offset={-50} data-scroll-nav="1">Decision Management System</Link></li>
                <li className='li-cursor'><Link onClick={() => setOption("Quality Management System")} to='three_option' smooth={true} duration={500} offset={-50} data-scroll-nav="1">Quality Management System</Link></li>
                <li className='li-cursor'><Link onClick={() => setOption("Training Management System")} to='three_option' smooth={true} duration={500} offset={-50} data-scroll-nav="1">Training Management System</Link></li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h5>Company</h5>
              <ul>
                <li><ReactDOMLink to="/privacy-policy">Privacy Policy</ReactDOMLink></li>
                <li><ReactDOMLink to="/contact">Contact</ReactDOMLink></li>
                <li><ReactDOMLink to="/blogs">Blogs</ReactDOMLink></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row credit align-items-center">
          <div className="col-md-auto order-md-2 text-end social-footer">
            <a href="https://www.linkedin.com/company/cxfirstai"><i className="fa fa-linkedin-square"></i></a>
          </div>
          <div className="col order-md-1 copyright text-start">
            Copyright &copy; <span>{new Date().getFullYear()}</span> CxFirst.ai Inc.. Powered By GLocal Travel Experiences Private Limited All Right Reserved | Made with love in India.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
