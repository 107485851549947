import React from 'react';

const PrivacyPolicy = () => {
  return(
    <>
     <div id="pageheader">
    <div className="container text-start">
      <h1>Privacy Policy</h1>
    </div>
  </div>
       <div id="content">
      <div className="container text-start">
        <p>
          The following terms and conditions (the "Agreement") govern all use of the{" "}
          <a href="https://cxfirst.ai/">
            <span style={{ color: "#1a1aff" }}>https://cxfirst.ai</span>
          </a>{" "}
          website (the "Site") and the products and services available on the Site. The
          products and services available on the Site are owned and operated by CX First LLP
          ("Powered By GLocal Travel Experiences Private Limited"). The Service is offered subject to your (the "User")
          acceptance without modification of all the terms and conditions contained herein
          and all other operating rules, policies and procedures that may be published from
          time to time on the Site by the Company.
        </p>

        <p>
          <strong>
            <b>
              BY USING OR ACCESSING ANY PART OF THE SERVICE, YOU AGREE TO ALL OF THE TERMS
              AND CONDITIONS CONTAINED HEREIN; IF YOU DON'T AGREE, DO NOT USE OR ACCESS THE
              SERVICE.
            </b>
          </strong>
        </p>

        <p>
          Company reserves the right, at its sole discretion, to modify or replace any of the
          terms or conditions of this agreement at any time. It is the user's responsibility
          to check this agreement periodically for changes. User's continued use of the
          service, following the posting of any changes to this agreement constitutes
          acceptance of those changes. Certain services available at the site may be subject
          to additional terms and conditions. To the extent such terms and conditions
          conflict with this agreement, such additional terms and conditions shall control.
        </p>

        <div className="accordion mt-4" id="collapse_tabs">
          {/* Personal Data Section */}
          <div className="card">
            <div
              aria-expanded="false"
              className="card-header accordion-button"
              data-bs-target="#cOne"
              data-bs-toggle="collapse"
            >
              Personal Data
            </div>
            <div className="collapse show" data-bs-parent="#collapse_tabs" id="cOne">
              <div className="card-body">
                <p>
                  The Administrator of your personal data is in accordance with the Information
                  Technology Act 2000, Information Technology Rules, 2011, and GDPR.
                </p>
                <p>
                  Purposes, legal bases, and the specified period of personal data processing
                  are described separately for each purpose of data processing (see the
                  provisions below for a detailed description of the different purposes of
                  data processing).
                </p>
              </div>
            </div>
          </div>

          {/* What do we do with your personal information? */}
          <div className="card">
            <div
              aria-expanded="false"
              className="card-header accordion-button collapsed"
              data-bs-target="#cTwo"
              data-bs-toggle="collapse"
            >
              What do we do with your personal information?
            </div>
            <div className="collapse" data-bs-parent="#collapse_tabs" id="cTwo">
              <div className="card-body">
                <p>
                  We make community impact through our impact sourcing model, also being a
                  member of Global Impact Sourcing Coalition. For every job we do for you,
                  there is a life impacted positively. As a responsible business, we have
                  committed to creating 4,000 jobs for the local youths, empowering them
                  financially, by the end of 2022. We accept community development as a shared
                  responsibility. We ensure this by giving special preference to unemployed
                  youths, specially abled and underprivileged women.
                </p>

                <p><strong>1. ACCESS</strong></p>
                <p>
                  User certifies to the company that user is at least 18 years of age. User
                  also certifies that it is legally permitted to use the service and takes
                  full responsibility for the selection and use of the service. This agreement
                  is void where prohibited by law, and User's right to access the service is
                  revoked in such jurisdictions.
                </p>

                <p><strong>2. PLACING A SERVICES REQUEST.</strong></p>
                <p>
                  When placing a service request or inquiry, you must provide data necessary
                  to complete the inquiry form.
                </p>

                {/* Add more sections as needed */}
              </div>
            </div>
          </div>

          {/* Add other collapsible sections here, following the same structure */}

        </div>
      </div>
    </div>
    </>
  );
};

export default PrivacyPolicy
